// src/data/teams.js

const teams = [
  {
    name: 'Adelaide',
    logo: '/logos/Adelaide.png',
  },
  {
    name: 'Brisbane',
    logo: '/logos/Brisbane.png',
  },
  {
    name: 'Carlton',
    logo: '/logos/Carlton.png',
  },
  {
    name: 'Collingwood',
    logo: '/logos/Collingwood.png',
  },
  {
    name: 'Essendon',
    logo: '/logos/Essendon.png',
  },
  {
    name: 'Fremantle',
    logo: '/logos/Fremantle.png',
  },
  {
    name: 'Geelong',
    logo: '/logos/Geelong.png',
  },
  {
    name: 'Gold Coast',
    logo: '/logos/Gold Coast.png',
  },
  {
    name: 'Greater Western Sydney',
    logo: '/logos/Greater Western Sydney.png',
  },
  {
    name: 'Hawthorn',
    logo: '/logos/Hawthorn.png',
  },
  {
    name: 'Melbourne',
    logo: '/logos/Melbourne.png',
  },
  {
    name: 'North Melbourne',
    logo: '/logos/North Melbourne.png',
  },
  {
    name: 'Port Adelaide',
    logo: '/logos/Port Adelaide.png',
  },
  {
    name: 'Richmond',
    logo: '/logos/Richmond.png',
  },
  {
    name: 'St Kilda',
    logo: '/logos/St Kilda.png',
  },
  {
    name: 'Sydney',
    logo: '/logos/Sydney.png',
  },
  {
    name: 'West Coast',
    logo: '/logos/West Coast.png',
  },
  {
    name: 'Western Bulldogs',
    logo: '/logos/Western Bulldogs.png',
  },
  {
    name: 'Bendigo Pioneers',
    logo: '/logos/Bendigo Pioneers.jpg',
  },
  {
    name: 'Brisbane Lions Academy',
    logo: '/logos/Brisbane Lions Academy.jpg',
  },
  {
    name: 'Calder Cannons',
    logo: '/logos/Calder Cannons.png',
  },
  {
    name: 'Dandenong Stingrays',
    logo: '/logos/Dandenong Stingrays.png',
  },
  {
    name: 'Eastern Ranges',
    logo: '/logos/Eastern Ranges.png',
  },
  {
    name: 'Geelong Falcons',
    logo: '/logos/Geelong Falcons.png',
  },
  {
    name: 'Gippsland Power',
    logo: '/logos/Gippsland Power.jpg',
  },
  {
    name: 'Gold Coast Suns Academy',
    logo: '/logos/Gold Coast Suns Academy.png',
  },
  {
    name: 'GWV Rebels',
    logo: '/logos/GWV Rebels.png',
  },
  {
    name: 'Murray Bushrangers',
    logo: '/logos/Murray Bushrangers.jpg',
  },
  {
    name: 'Northern Knights',
    logo: '/logos/Northern Knights.png',
  },
  {
    name: 'Northern Terrority',
    logo: '/logos/Northern Terrority.png',
  },
  {
    name: 'Oakleigh Chargers',
    logo: '/logos/Oakleigh Chargers.jpg',
  },
  {
    name: 'Sandringham Dragons',
    logo: '/logos/Sandringham Dragons.png',
  },
  {
    name: 'Sydney Swans Academy',
    logo: '/logos/Sydney Swans Academy.png',
  },
  {
    name: 'Tassie Devils',
    logo: '/logos/Tassie Devils.png',
  },
  {
    name: 'Western Jets',
    logo: '/logos/Western Jets.png',
  }, 
  {
    name: 'Subiaco',
    logo: '/logos/Subiaco.jpeg',
  },
  {
    name: 'Peel Thunder',
    logo: '/logos/Peel Thunder.png',
  },
  {
    name: 'South Adelaide',
    logo: '/logos/South Adelaide.png',
  },
  {
    name: 'Glenelg',
    logo: '/logos/Glenelg.png',
  },
  {
    name: 'Claremont',
    logo: '/logos/Claremont.jpg',
  },
  {
    name: 'Sturt',
    logo: '/logos/Sturt.png',
  },
  {
    name: 'West Perth',
    logo: '/logos/West Perth.png',
  }
];

export default teams;
