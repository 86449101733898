import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useHistory, useParams } from 'react-router-dom';

function PlayerProfile() {
  const [prospects, setProspects] = useState([]);
  const [selectedPlayer, setSelectedPlayer] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const history = useHistory();
  const { playerName } = useParams(); // Get the playerName from the URL

  useEffect(() => {
    // Fetch player prospects
    axios.get(process.env.REACT_APP_BASEURL + '/api/prospects')
      .then(response => {
        setProspects(response.data);
        setLoading(false);
      })
      .catch(error => {
        console.error('Error fetching prospects:', error);
        setError(error);
        setLoading(false);
      });
  }, []);

  // Update selected player when the URL changes or prospects are loaded
  useEffect(() => {
    if (playerName && prospects.length > 0) {
      const player = prospects.find(p => 
        p.playerName.toLowerCase().replace(/\s+/g, '-') === playerName.toLowerCase()
      );
      setSelectedPlayer(player || null);
    }
  }, [playerName, prospects]);

  const handlePlayerSelect = (event) => {
    const selectedName = event.target.value;
    const formattedName = selectedName.toLowerCase().replace(/\s+/g, '-'); // Convert name to URL format
    history.push(`/player/${formattedName}`); // Update the URL
  };

  const handleImageError = (e) => {
    e.target.onerror = null; // Prevent infinite loop in case both .png and .jpg fail
    e.target.src = e.target.src.replace('.png', '.jpg'); // Try .jpg if .png fails
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error.message}</div>;

  return (
    <div className="page-section-top">
      <h1>2024 Draft Profiles</h1>
      <p>Learn more about the next generation of AFL stars</p>
      
      {/* Dropdown to select player */}
      <select 
        onChange={handlePlayerSelect} 
        value={selectedPlayer ? selectedPlayer.playerName : 'Select'}
      >
        <option disabled value="Select">Select</option>
        {prospects.map((prospect, index) => (
          <option key={index} value={prospect.playerName}>
            {prospect.playerName}
          </option>
        ))}
      </select>

      {/* Player profile section */}
      {selectedPlayer ? (
        <div className="player-profile">
          <h2>{selectedPlayer.playerName}</h2>
          <div className="team-info">
            <img 
              src={`/logos/${selectedPlayer.teamName}.png`} 
              alt={`${selectedPlayer.teamName} logo`} 
              onError={handleImageError} // Handle image error if .png fails
            />
            <div>
              <strong>Current Team: </strong>{selectedPlayer.teamName}
            </div>
          </div>
          <div className="player-details">
            <strong>Position: </strong>{selectedPlayer.position} &nbsp; 
            <strong>Height: </strong>{selectedPlayer.height}
          </div>
          <p>{selectedPlayer.description}</p>
          <div className="attributes">
            {selectedPlayer.bestAttributes.map((attr, index) => (
              <span key={index} className="attribute">{attr}</span>
            ))}
          </div>
        </div>
      ) : (
        <p>Select a player to view their profile.</p>
      )}
    </div>
  );
}

export default PlayerProfile;
