import React, { useEffect, useState } from 'react';
import axios from 'axios';

function MockDraft() {
  const [draftOrder, setDraftOrder] = useState([]);
  const [prospects, setProspects] = useState([]);
  const [draftResults, setDraftResults] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [simulating, setSimulating] = useState(false);
  const [displayedResults, setDisplayedResults] = useState([]);
  const [buttonText, setButtonText] = useState('Generate Draft');
  const [showTable, setShowTable] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const draftOrderResponse = await axios.get(process.env.REACT_APP_BASEURL + '/api/draft-order');
        setDraftOrder(draftOrderResponse.data);

        const prospectsResponse = await axios.get(process.env.REACT_APP_BASEURL + '/api/prospects');
        setProspects(prospectsResponse.data);

        setLoading(false);
      } catch (error) {
        console.error('There was an error fetching the data!', error);
        setError(error);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const simulateDraft = () => {
    setSimulating(true);
    setShowTable(false);
    setButtonText('Simulating...');
    setDisplayedResults([]); // Clear previous results

    setTimeout(() => {
      const academyPlayers = [
        { playerName: 'Levi Ashcroft', teamName: 'Brisbane - Bid', position: 'Midfielder', height: '179cm', draftRange: [1, 3], logo: '/logos/Brisbane.png' },
        { playerName: 'Leo Lombard', teamName: 'Gold Coast - Bid', position: 'Midfielder', height: '185cm', draftRange: [8, 12], logo: '/logos/Gold Coast.png' },
        { playerName: 'Isaac Kako', teamName: 'Essendon - Bid', position: 'Forward', height: '175cm', draftRange: [7, 15], logo: '/logos/Essendon.png' },
        { playerName: 'Sam Marshall', teamName: 'Brisbane - Bid', position: 'Midfielder', height: '185cm', draftRange: [20, 26], logo: '/logos/Brisbane.png' }
      ];

      let academyPositions = academyPlayers.map(player => ({
        ...player,
        draftPosition: getRandomPosition(player.draftRange)
      }));

      academyPositions.sort((a, b) => a.draftPosition - b.draftPosition);

      let adjustedDraftOrder = [...draftOrder];

      // Place Academy Players in their specified positions
      academyPositions.forEach(ap => {
        adjustedDraftOrder.splice(ap.draftPosition - 1, 0, {
          teamName: ap.teamName,
          logo: ap.logo,
          isAcademyPick: true,
        });
      });

      const availableProspects = prospects.filter(prospect =>
        !academyPositions.some(ap => ap.playerName === prospect.playerName)
      );

      // Group the players into their respective tiers
      const tiers = {
        '1': availableProspects.filter(prospect => prospect.tier === '1'),
        '2': availableProspects.filter(prospect => prospect.tier === '2'),
        '3': availableProspects.filter(prospect => prospect.tier === '3'),
        '4': availableProspects.filter(prospect => prospect.tier === '4'),
      };

      // Shuffle players within each tier to introduce randomness
      Object.keys(tiers).forEach(tier => {
        tiers[tier] = shuffleArray(tiers[tier]);
      });

      let finalDraftResults = academyPositions.map(ap => {
        const originalProspect = prospects.find(prospect => prospect.playerName === ap.playerName);
        return {
          draftPosition: ap.draftPosition,
          playerName: ap.playerName,
          teamName: ap.teamName,
          position: ap.position,
          height: ap.height,
          logo: ap.logo,
          prospectTeam: originalProspect ? originalProspect.teamName : 'Unknown',
          bestAttributes: originalProspect ? originalProspect.bestAttributes : [] // Add key attributes here
        };
      });

      // Loop through each team in the adjusted draft order and assign players based on tier
      adjustedDraftOrder.forEach((team, index) => {
        const draftPosition = index + 1;

        if (finalDraftResults.length >= 30) return; // Stop after selecting 30 players

        if (!finalDraftResults.some(dr => dr.draftPosition === draftPosition)) {
          let selectedPlayer;

          // Assign players to the correct draft range based on their tier
          if (tiers['1'].length > 0 && draftPosition <= 4) {
            selectedPlayer = tiers['1'].shift(); // Tier 1 for positions 1–3
          } else if (tiers['2'].length > 0 && draftPosition <= 9) {
            selectedPlayer = tiers['2'].shift(); // Tier 2 for positions 4–8
          } else if (tiers['3'].length > 0 && draftPosition <= 17) {
            selectedPlayer = tiers['3'].shift(); // Tier 3 for positions 8–18
          } else if (tiers['4'].length > 0) {
            selectedPlayer = tiers['4'].shift(); // Tier 4 for positions 19–30
          }

          // Fallback: If no player selected from tiers, take any available player
          if (!selectedPlayer && availableProspects.length > 0) {
            selectedPlayer = availableProspects.shift();
          }

          // If selectedPlayer exists, add to final draft results
          if (selectedPlayer) {
            const originalProspect = prospects.find(prospect => prospect.playerName === selectedPlayer.playerName);
            finalDraftResults.push({
              draftPosition: draftPosition,
              playerName: selectedPlayer.playerName,
              teamName: team.teamName,
              position: selectedPlayer.position,
              height: selectedPlayer.height,
              logo: `/logos/${team.teamName}.png`,
              prospectTeam: originalProspect ? originalProspect.teamName : 'Unknown',
              bestAttributes: originalProspect ? originalProspect.bestAttributes : [] // Add key attributes here
            });
          }
        }
      });

      finalDraftResults.sort((a, b) => a.draftPosition - b.draftPosition);

      // One by one reveal logic
      let i = 0;
      setShowTable(true);
      const interval = setInterval(() => {
        setDisplayedResults(prev => [...prev, finalDraftResults[i]]);
        i++;
        if (i >= finalDraftResults.length || i >= 30) {
          clearInterval(interval);
          setButtonText('Try Again');
        }
      }, 500); // Simulate the time between each pick being revealed

      setDraftResults(finalDraftResults);
      setSimulating(false);
    }, 3000);
  };

  const getRandomPosition = ([min, max]) => {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  };

  const shuffleArray = (array) => {
    const shuffledArray = [...array];
    for (let i = shuffledArray.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]];
    }
    return shuffledArray;
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error.message}</div>;

  return (
    <div className="page-section-top">
      <h1>2024 AFL Mock Draft</h1>
      <p>Curious about where your top prospects could land? Hit "Generate Draft" to find out!</p>
      <button onClick={simulateDraft} disabled={simulating}>
        {buttonText}
      </button>
      {simulating ? (
        <div className="loader"></div>
      ) : (
        showTable && (
          <div className="draft-results">
            {displayedResults.map((result, index) => (
              <div key={index} className="draft-card">
                <div className="draft-card-header">
                  <img src={result.logo} alt={`${result.teamName} logo`} className="team-logo" />
                  <div className="team-name">{result.teamName}</div>
                </div>
                <div className="player-info">
                  <div className="player-name">{result.playerName}</div>
                  <div className="player-team">{result.prospectTeam}</div>
                  <div className="position-height">
                    <div className="position">{result.position}</div>
                    <div className="height">{result.height}</div>
                  </div>
                </div>
                {/* Display key attributes */}
                <div className="key-attributes">
                  <div className="attributes-list">
                    {result.bestAttributes.map((attribute, idx) => (
                      <span key={idx} className="label">{attribute}</span>
                    ))}
                  </div>
                </div>
                <div className="draft-pick">{result.draftPosition}</div>
              </div>
            ))}
          </div>
        )
      )}
    </div>
  );
}

export default MockDraft;

