import React from 'react';
import { Link } from 'react-router-dom';

function About() {
  return (
    <div className="page-section-top">
      <h1>About theTANK</h1>
      <p>
        theTANK is the ultimate AFL Draft tool, designed to provide AFL fans, analysts, and recruiters with a comprehensive platform for exploring draft prospects and mock drafts. Whether you're a die-hard footy fan, a data-driven analyst, or just a curious list boss, theTANK has you covered.
      </p>
      <p>
        With our mock draft engine, driven by a consensus big board and AFL Academy bids, you can simulate the upcoming draft and see where top prospects might land.
      </p>
      <p>
        theTANK is currently in BETA v1.1, and we’re always looking to improve our player values and team needs. If you have feedback, we’d love to hear 
        from you <a href="mailto:thetank.aus@gmail.com">by sending us your ideas.</a> Let’s take your team passion to the next level as a list boss with theTANK!
      </p>
    </div>
  );
}

export default About;
