import React from 'react';
import { Link } from 'react-router-dom';

function Footer() {
  return (
    <footer>
      <div className="links">
		    <Link to="/mock">Mock Draft</Link>
        <Link to="/">Draft Order</Link>
        <Link to="/board">Big Board</Link>
        <Link to="/about-us">About Us</Link>
      </div>
      <div className="footer-feedback">
        <a href="mailto:thetank.aus@gmail.com">Send Us Feedback</a>
        <p className="copyright">© 2024 thetank.au. All rights reserved.</p>
      </div>
    </footer>
  );
}

export default Footer;
