import React, { useEffect, useState } from 'react';
import axios from 'axios';

function DraftOrder() {
  const [draftOrder, setDraftOrder] = useState([]);
  const [teamPoints, setTeamPoints] = useState([]);
  const [teamPicks, setTeamPicks] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    axios.get(process.env.REACT_APP_BASEURL + '/api/draft-order')
      .then(response => {
        setDraftOrder(response.data);
        calculateTeamPointsAndPicks(response.data); // Calculate total points and picks by team
        setLoading(false);
      })
      .catch(error => {
        console.error("There was an error fetching the draft order!", error);
        setError(error);
        setLoading(false);
      });
  }, []);

  const calculateTeamPointsAndPicks = (draftOrderData) => {
    const pointsByTeam = {};
    const picksByTeam = {};

    // Sum up points and picks for each team
    draftOrderData.forEach(order => {
      if (!pointsByTeam[order.teamName]) {
        pointsByTeam[order.teamName] = 0;
        picksByTeam[order.teamName] = [];
      }
      pointsByTeam[order.teamName] += parseInt(order.draftPoints, 10);
      picksByTeam[order.teamName].push(order.draftPosition);
    });

    // Convert the objects to arrays of {teamName, totalPoints, picks} objects
    const teamPointsAndPicksArray = Object.keys(pointsByTeam).map(teamName => ({
      teamName,
      totalPoints: pointsByTeam[teamName],
      picks: picksByTeam[teamName].join(', '), // Create a comma-separated string of picks
    }));

    // Sort the teamPointsAndPicksArray alphabetically by teamName
    teamPointsAndPicksArray.sort((a, b) => a.teamName.localeCompare(b.teamName));

    setTeamPoints(teamPointsAndPicksArray);
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error.message}</div>;

  return (
    <div className="page-section-top">
      <h1>AFL Draft Order 2024</h1>
      <p>Your clubs picks and points for the upcoming draft</p>

      {/* Draft Order Table */}
      <table>
        <thead>
          <tr>
            <th>Draft Pick</th>
            <th>Team Name</th>
            <th>Draft Points</th>
          </tr>
        </thead>
        <tbody>
          {draftOrder.map((order, index) => (
            <tr key={index}>
              <td>{order.draftPosition}</td>
              <td>
                <img src={`/logos/${order.teamName}.png`} alt={`${order.teamName} logo`} style={{ width: '20px', marginRight: '10px' }} />
                {order.teamName}
              </td>
              <td>{order.draftPoints}</td>
            </tr>
          ))}
        </tbody>
      </table>

      {/* Total Points and Picks by Team Table */}
      <h2>Total Draft Points and Picks by Team</h2>
      <table>
        <thead>
          <tr>
            <th>Team Name</th>
            <th>Total Points</th>
            <th>Picks Held</th>
          </tr>
        </thead>
        <tbody>
          {teamPoints.map((team, index) => (
            <tr key={index}>
              <td>
                <img src={`/logos/${team.teamName}.png`} alt={`${team.teamName} logo`} style={{ width: '20px', marginRight: '10px' }} />
                {team.teamName}
              </td>
              <td>{team.totalPoints}</td>
              <td>{team.picks}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default DraftOrder;
